import "./App.css";
import React, { useState,  useEffect } from "react";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import axios from 'axios';


function App() {
    const [qr, handleQR] = useState("");
    const [subscription, handleSubscription] = useState("");
    const [isScanActive, handleIsScanActive] = useState(false);
    let [firstName, setName] = useState("");
    let [lastName, setLastName] = useState("");
    let [motherLastName, setMotherLastName] = useState("");

    const onUpdate = async (err, result) => {
        if (result) {
            handleQR(result.text);
            handleSubscription(result.text);
            handleIsScanActive(false);
        } else {
            handleQR("Scanning...");
        }
    };

    const showScan = () => {
        handleQR('');
        handleSubscription('');
        handleIsScanActive(true);
        setName('');
        setLastName('');
        setMotherLastName('');
    };

    const getName = async(userId) => {
        try {
            const response = await axios.post('https://mc.vectorag.com.mx/visit', { "id": userId })
            const name = response.data;
            setName(name.firstName);
            setLastName(name.lastName);
            setMotherLastName(name.motherLastName);
            
        } catch (error) {
          console.log(error);
            return error;
        }
    };

    useEffect(() => {
      getName(subscription)
  }, [subscription])

    return ( <div className = "App" >
        <div className = "container-fluid qrTitle" >
        <h1> Registro de entrada de usuarios </h1> <div className = "row content" >
        <div className = "col-md-12 text-center"> {
            isScanActive ? ( <BarcodeScannerComponent width = { 450 } height = { 450 } onUpdate = { onUpdate }/>) : null
            }

        <button className = "u-full-width button button-primary"onClick = { showScan } >
        Leer código </button> { } <h1 > Bienvenido: { firstName + ' ' + lastName + ' ' + motherLastName } </h1> </div> </div> </div> </div>
    );
}

export default App;